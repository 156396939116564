.course-next-previous-btn-container{
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 20px;
    top: 33px;
    position: absolute;
    right: 10px;
    z-index: 99;
}
@media screen and (min-width: 768px) {
    .course-next-previous-btn-container{
        position: static;
        padding-bottom: 10px;
    }
}
.p-relative{
    position: relative;
}
.course-browse-btn-block{
    display: flex;
    align-items: center;
    gap: 10px;
    cursor: pointer;
}
.course-browse-btn-block:hover{
    opacity: .8;
}
.course-browse-icon-container{
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--accent);
    border-radius: 50%;
}
.course-next-previous-btn-container span{
    color:var(--accent)
}
@media screen and (max-width: 480px) {
    .course-next-previous-btn-container{
        gap: 10px;
        top: -29px;
        right: 0;
        left: 19px;
        padding-right: 10px;
    }
    .course-browse-icon-container{
        width: 30px;
        height: 30px;
    }
}